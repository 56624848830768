import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { Dropdown, headerHeight } from '../shared/elements'
import {
  getCurrentUser,
  getCurrentWebsite,
  setCurrentWebsite,
} from '../../services/auth'
import * as paths from '../../utils/paths'

export const sidenavWidth = 300

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - ${headerHeight}px);
  width: ${sidenavWidth}px;
  margin-top: ${headerHeight}px;
  padding-bottom: 42px;
  background: var(--aside-background);
  overflow: auto;
  z-index: 998;

  .website-select-container {
    position: relative;

    .label {
      position: absolute;
      top: 30px;
      left: 40px;
      font-size: 12px;
      color: #404A78;
      pointer-events: none;
    }

    .arrow {
      position: absolute;
      top: 55px;
      right: 40px;
      pointer-events: none;

      svg {
        width: 14px;
      }
    }

    .open ~ .arrow {
      transform: rotate(180deg);
    }

    .website-container {
      height: 100px;
      padding: 49px 30px 20px 30px;
    }

    .website-select {
      width: 100%;
      height: 34px;
      padding-left: 8px;
      padding-right: 26px;
      border: 2px solid transparent;
      border-radius: 6px;
      background: transparent;
      font-family: var(--head-font);
      font-size: 13px;
      text-align: left;
      appearance: none;
      outline: none;
      cursor: pointer;

      &.open {
        border: 2px solid var(--header-background-hover);
      }
    }

    .website-options {
      width: auto;
      top: 88px;
      left: 30px;
      right: 30px;
      padding: 5px;
      z-index: 999;
    }

    .website-option {
      width: 100%;
      height: 28px;
      padding: 6px 5px;
      border-radius: 14px;
      font-family: var(--body-font);
      font-size: 12px;
      font-weight: 500;
      text-align: left;

      &:hover {
        background: var(--text-muted);
      }
    }
  }

  .aside-line {
    width: calc(100% - 32px);
    margin: 0 auto;
    border-top: 1px solid #f1f1f1;
  }

  ul {
    padding: 25px 16px 0;
    margin: 0;
    list-style: none;
  }

  li {
    a,
    .a {
      position: relative;
      display: flex;
      width: 100%;
      padding: 16px 26px 16px 35px;
      margin-bottom: 6px;
      border-radius: 27px;
      overflow: hidden;

      svg {
        width: 22px;
        height: 22px;
        margin-right: 18px;
      }

      span {
        margin-top: 4px;
        line-height: 18px;
        font-size: 13px;
        font-weight: normal;
        color: black;
      }

      .small {
        margin-left: 10px;
        font-size: 10px;
        color: #ababab;
      }

      &:hover {
        background: var(--aside-background-hover);
      }

      &.active {
        background: var(--aside-background-hover);
      }
    }
  }
`

const getHightlight = (highlight) => {
  if (!highlight) {
    return false
  }

  if (typeof highlight === 'string') {
    return window.location.pathname.startsWith(highlight)
  }

  if (typeof highlight === 'object' && Array.isArray(highlight)) {
    for (const path of highlight) {
      if (window.location.pathname.startsWith(path)) {
        return true
      }
    }
  }

  return false
}

const NavLink = (props) => {
  const isCurrent =
    (props.to === '/'
      ? window.location.pathname === '/'
      : window.location.pathname.startsWith(props.to)) ||
    getHightlight(props.highlight)

  return <Link className={isCurrent ? 'active' : ''} {...props} />
}

export default function Sidenav(props) {
  const currentUser = getCurrentUser()
  const currentWebsite = getCurrentWebsite()

  const changeCurrentWebsite = (newWebsite) => () => {
    setCurrentWebsite(newWebsite)
    window.location.reload()
  }

  return (
    <Aside>
      <div className="aside-top">
        <div className="website-select-container">
          <Dropdown
            containerClassName="website-container"
            buttonClassName="website-select"
            listClassName="website-options"
            button={<span>{currentWebsite}</span>}
          >
            {currentUser?.websites?.map((website) => (
              <li key={website}>
                <button
                  className="website-option"
                  onClick={changeCurrentWebsite(website)}
                >
                  {website}
                </button>
              </li>
            ))}
          </Dropdown>
          <div className="label">Website</div>
          <div className="arrow">
            <SVG src="/images/icons/arrow-down.svg" />
          </div>
        </div>
        <div className="aside-line" />
        <ul>
          <li>
            <NavLink to={paths.rootPath()}>
              <SVG src="/images/icons/aside-overview.svg" />
              <span>Overview</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={paths.conversionsPath()}>
              <SVG src="/images/icons/aside-conversions.svg" />
              <span>Conversions</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={paths.billingPath()}>
              <SVG src="/images/icons/aside-billing.svg" />
              <span>Billing</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={paths.accountPath()}>
              <SVG src="/images/icons/aside-account.svg" />
              <span>Account</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </Aside>
  )
}
