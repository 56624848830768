import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useForm } from 'react-hook-form'
import { loginLayoutStyles, LoginIllustration } from '../shared/elements'
import { TextField } from '../shared/fields'
import { login } from '../../services/auth'
import { forgotPasswordPath } from '../../utils/paths'

const Container = styled.div`
  ${loginLayoutStyles}
`

export default function LoginForm(props) {
  const { setAuthenticated } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (values) => {
    setError('')
    setSubmitting(true)

    const loggedIn = await login(values)
    setSubmitting(false)

    if (loggedIn) {
      setAuthenticated(true)
    } else {
      setError('Incorred email or password')
    }
  }

  return (
    <Container>
      <LoginIllustration
        head="Welcome back."
        body="Log in to your account to start managing your paid sources."
      />
      <div className="form-container">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <SVG src="/images/logo-dark.svg" className="head-logo" />
          <h3>Welcome back.</h3>
          <div className="row mt-4">
            <div className="col-md-10">
              <p>
                Log in to your Rewardsweb account.
                <br />
                Are you having trouble signing in? Try resetting your password,
                or write us at{' '}
                <a href="mailto:network@rewardsweb.com">
                  network@rewardsweb.com
                </a>
              </p>
            </div>
          </div>

          <div className="mt-4">
            <TextField
              form={form}
              validation={{ required: true }}
              type="email"
              name="email"
              label="Email address"
            />

            <TextField
              form={form}
              validation={{ required: true }}
              type="password"
              name="password"
              label="Password"
            />

            <div className="text-right">
              <Link to={forgotPasswordPath()} className="forgot-password-link">
                Forgot password?
              </Link>
            </div>
          </div>

          {error && <div className="text-danger mb-3">{error}</div>}

          <div className="actions mt-3">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary"
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    </Container>
  )
}
